<template>
    <div class="reference-books">

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0">Справочники</h1>

        </div>

        <div class="d-flex mb-3 align-items-center">

            <b-form-select
                v-model="selectedReferenceBook"
                :options="referenceBooks"
                class="mr-3"
                style="max-width: 350px"/>

            <BInputGroup style="max-width: 350px">
                <BFormInput
                    type="text"
                    class="border-right-0"
                    placeholder="Поиск"
                    v-model="filter"/>
                <BInputGroupAppend>
                    <BInputGroupText
                        class="bg-transparent border-left-0">
                        <BIcon
                            icon="search"
                            class="text-primary"/>
                    </BInputGroupText>
                </BInputGroupAppend>
            </BInputGroup>

            <div class="ml-auto"></div>

            <div
                v-if="selectedReferenceBook === 'nomenclature'"
            >
                <BButton
                    class="ml-3"
                    variant="danger"
                    v-on:click="exportNomenclature"
                >Экспорт    
                </BButton>
            </div>
        </div>

        <DefaultTable
            v-if="selectedReferenceBook === 'nomenclature'"
            :data="tableList"
            :fields="tableFields"
            :loading="nomenclaturesLoading"
            rowLink="/reference-books/nomenclature"
            emptyText="Список пуст"
            :noLocalSorting="true"
            @sort-change="nomenclaturesSorting"
            @pagination-change="nomenclaturesPaginationChange"
            @per-page-change="nomenclaturesPerPageChange"
        />

        <DefaultTable
            v-else-if="selectedReferenceBook === 'contrAgents'"
            :data="tableList"
            :fields="tableFields"
            :loading="nomenclaturesLoading"
            emptyText="Список пуст"
            :noLocalSorting="true"
            @sort-change="contragentsSorting"
            @pagination-change="contragentsPaginationChange"
            @per-page-change="contragentsPerPageChange"
        />

        <DefaultTable
            v-else
            :data="tableList"
            :fields="tableFields"
            :filter="filter"
            :loading="loading"
            :pagination="false"
            emptyText="Список пуст"
        />

    </div>
</template>

<script>

import { mapState } from 'vuex'
import DefaultTable from '@/components/Tables/Default'
import axios from 'axios'

export default {
    name: "ReferenceBooks",
    components: {
        DefaultTable
    },
    computed: {
        ...mapState({
            organizations: state => state.referenceBooks.organizations,
            workAgreements: state => state.referenceBooks.workAgreements,
            providersContracts: state => state.referenceBooks.providersContracts,
            objects: state => state.referenceBooks.objects,
            contrAgents: state => state.referenceBooks.contrAgents,
            nomenclature: state => state.referenceBooks.nomenclature
        }),
        tableList () {
            return this[this.selectedReferenceBook]
        },
        tableFields () {
            return this[this.selectedReferenceBook + 'Fields']
        }
    },
    data: () => ({
        referenceBooks: [
            {
                value: 'organizations',
                text: 'Организации'
            },
            {
                value: 'workAgreements',
                text: 'Трудовые договоры'
            },
            {
                value: 'providersContracts',
                text: 'Договоры поставки'
            },
            {
                value: 'objects',
                text: 'Объекты'
            },
            {
                value: 'contrAgents',
                text: 'Контрагенты'
            },
            {
                value: 'nomenclature',
                text: 'Номенклатуры'
            }
        ],
        organizationsFields: [
            {
                key: 'name',
                sortable: true,
                label: 'Название',
                full: 'Название',
                openDefault: true
            }
        ],
        workAgreementsFields: [
            {
                key: 'number',
                sortable: true,
                label: 'Номер',
                full: 'Номер',
                openDefault: true
            },
            {
                key: 'date',
                sortable: true,
                label: 'Дата',
                full: 'Дата',
                openDefault: true
            }
        ],
        providersContractsFields: [
            {
                key: 'number',
                sortable: true,
                label: 'Номер',
                full: 'Номер',
                openDefault: true
            },
            {
                key: 'date',
                sortable: true,
                label: 'Дата',
                full: 'Дата',
                openDefault: true
            }
        ],
        objectsFields: [
            {
                key: 'name',
                sortable: true,
                label: 'Название',
                full: 'Название',
                openDefault: true
            },
            {
                key: 'sub_objects',
                sortable: true,
                label: 'Субъект',
                full: 'Субъект',
                openDefault: true
            }
        ],
        contrAgentsFields: [
            {
                key: 'name',
                sortable: true,
                label: 'Название',
                full: 'Название',
                openDefault: true
            },
            {
                key: 'active-before-date',
                sortable: true,
                label: 'Дата',
                full: 'Дата',
                openDefault: true
            },
            {
                key: 'contacts',
                sortable: true,
                label: 'Контакты',
                full: 'Контакты',
                openDefault: true
            }
        ],
        nomenclatureFields: [
            {
                key: 'name',
                sortable: true,
                label: 'Название',
                full: 'Название',
                openDefault: true
            },
            {
                key: 'mnemocode',
                sortable: true,
                label: 'Мнемокод',
                full: 'Мнемокод',
                openDefault: true
            },
            {
                key: 'unit',
                sortable: true,
                label: 'Ед. измерения',
                full: 'Единица измерения',
                openDefault: true
            },
            {
                key: 'price',
                sortable: true,
                label: 'Цена',
                full: 'Цена',
                openDefault: true
            }
        ],
        selectedReferenceBook: 'organizations',
        filter: '',
        loading: false,
        nomenclaturesLoading: false,
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Справочники' }
        ],
        nomenclaturesSortingData: {
            sort_field: '',
            sort_order: '',
            page: '',
            per_page: 25
        },
        contragentsSortingData: {
            sort_field: '',
            sort_order: '',
            page: '',
            per_page: 25
        }
    }),
    methods: {
        async updateNomenclaturesWithFilters () {
            this.nomenclaturesLoading = true
            await this.$store.dispatch('getNomenclaturesWithFilters', this.nomenclaturesSortingData)
            this.nomenclaturesLoading = false
        },
        async updateContragentsWithFilters () {
            this.nomenclaturesLoading = true
            // await this.$store.dispatch('getContragentsWithFilters', this.contragentsSortingData)
            const response = await axios.get( '/references/contr_agents', { params: this.contragentsSortingData } )
            this.$store.commit( 'setReferenceBooks', {
                book: 'contrAgents',
                data: response.data.data
            } )
            this.nomenclaturesLoading = false
        },
        nomenclaturesSorting (event) {
            this.nomenclaturesSortingData.sort_field = event.sortBy
            this.nomenclaturesSortingData.sort_order = event.sortDesc ? 'desc' : 'asc'
            this.updateNomenclaturesWithFilters()
        },
        contragentsSorting (event) {
            this.contragentsSortingData.sort_field = event.sortBy
            this.contragentsSortingData.sort_order = event.sortDesc ? 'desc' : 'asc'
            this.updateContragentsWithFilters()
        },
        nomenclaturesPaginationChange (page) {
            this.nomenclaturesSortingData.page = page
            this.updateNomenclaturesWithFilters()
        },
        contragentsPaginationChange (page) {
            this.contragentsSortingData.page = page
            this.updateContragentsWithFilters()
        },
        nomenclaturesPerPageChange (perPage) {
            this.nomenclaturesSortingData.page = ''
            this.nomenclaturesSortingData.per_page = perPage
            this.updateNomenclaturesWithFilters()
        },
        contragentsPerPageChange (perPage) {
            this.contragentsSortingData.page = ''
            this.contragentsSortingData.per_page = perPage
            this.updateContragentsWithFilters()
        },
        async exportNomenclature() {
            const baseURL = (
                ( process.env.NODE_ENV === 'production' )
                    ? process.env.VUE_APP_API_URL
                    : process.env.VUE_APP_API_DEV_URL
            )
            const token = this.$keycloak.token

            const url = baseURL + 'references/nomenclature?format=excel'
            const options = {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
            try {
                const response = await fetch( url, options )
                const data = await response.json()
                this.$bvToast.toast( data.message, {
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'success'
                } )
            } catch( error ) {
                console.error( error )
            }
        }
    },
    mounted() {
        this.updateNomenclaturesWithFilters()
    },
    watch: {
        async filter( event ) {
            if ( this.selectedReferenceBook === 'nomenclature' ) {
                this.nomenclaturesSortingData.search = event
                this.updateNomenclaturesWithFilters()
            } else if ( this.selectedReferenceBook === 'contrAgents' ) {
                this.contragentsSortingData.search = event
                this.updateContragentsWithFilters()
            }
        }
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
